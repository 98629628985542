import {createRouter, createWebHistory} from 'vue-router';
import Landing                          from '@/views/Landing.vue';
import SignIn                           from '@/views/SignIn.vue';
import SignUp                           from '@/views/SignUp.vue';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            component: Landing
        },
        {
            path: '/signin',
            name: 'signin',
            component: SignIn
        },
        {
            path: '/signup',
            name: 'signup',
            component: SignUp
        },
        {
            path: '/about',
            name: 'about',
            component: () => import('@/views/About.vue')
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: () => import( '@/views/Privacy.vue')
        },
        {
            path: '/terms',
            name: 'terms',
            component: () => import( '@/views/Terms.vue')
        },
        {
            path: '/account',
            name: 'account',
            component: () => import( '@/views/Account.vue')
        },
        {
            path: '/err/:msg',
            name: 'error',
            component: () => import('@/views/Error.vue')
        }
    ]
});

router.beforeEach((to, from) => {

});
export default router;
