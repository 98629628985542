<script setup>
import {useRouter}           from 'vue-router';

const router = useRouter();

</script>

<template>
    <section class='w-full min-h-screen flex flex-col justify-between items-center p-16'>
        <h1 class='text-center mt-auto text-2xl font-bold sm:text-3xl md:text-4xl'>
            Welcome to ClickLikeGive.com</h1>

        <p class='sm:mt-4 sm:block'>
            Where you can reward your favorite creators based on what you watch/read/listen and on a budget that is affordable for you. </p>
        <div class='btn-group flex-col m-8'>
            <button type='button' class='landing-button' @click='router.push("/signin")'>
                <span>I have an account, I want to login</span>
            </button>
            <button type='button' class='landing-button' @click='router.push("/signup")'>
                I do not have an account yet, I need to create one.
            </button>
        </div>
        <!--                <div class='grid grid-cols-2 gap-4 md:grid-cols-1 lg:grid-cols-2'>-->
        <!--                    <img alt='Supporter'-->
        <!--                         src='https://images.unsplash.com/photo-1615890643735-6cd26210d646?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHdhdGNoaW5nJTIweW91dHViZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60'-->
        <!--                         class='h-40 w-full object-cover sm:h-56 md:h-full'/>-->

        <!--                    <img alt='Creator'-->
        <!--                         src='https://images.unsplash.com/photo-1487537708572-3c850b5e856e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29uJTIwZWRpdGluZyUyMHZpZGVvfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60'-->
        <!--                         class='h-40 w-full object-cover sm:h-56 md:h-full'/>-->
        <!--                </div>-->
    </section>
</template>

<style scoped lang='postcss'>
.landing-button {
    @apply w-full m-4 inline-block shrink-0 rounded-md border border-black px-12 py-3 text-sm font-medium text-black
}
</style>
