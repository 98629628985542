<script setup>
import {ref}       from 'vue';
import {useRouter} from 'vue-router';
import axios       from 'axios';

const router = useRouter();

const emailAddress = ref('');
const password = ref('');

const apiClient = axios.create({
    baseURL: 'https://api.clicklikegive.com/auth',
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

const signIn = async () => {
    try {
        const response = await apiClient.post('/signIn', {email_address: emailAddress.value, password: password.value});
        if (response.status === 200) {
            router.push('/account');
        } else {
            console.error(`${response.status} ${response.statusText}`);
        }
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                // errors.value = err.response.data.errors
                router.push('/signIn')
            } else {
                console.error(err);
            }
        } else if (err.request) {
            console.error(err.request);
        } else {
            console.error('Error:', err);
        }
    }
};

</script>

<template>
    <section class='w-full min-h-screen flex flex-col justify-between items-center p-16'>
        <h1 class='text-center mt-auto text-2xl font-bold sm:text-3xl md:text-4xl'>
            Welcome back to <a href='/www/public'>ClickLikeGive.com</a></h1>
        <p class='mt-8 leading-relaxed'>
            Sign In to support your favorite content creators.
        </p>
        <div class=' w-full flex flex-col mt-8 space-y-8'>
            <ul v-if='errors'>
                <li v-for='(error, index) in errors'
                    class='text-red-500'
                    :key='index'>{{ error }}
                </li>
            </ul>
            <div class='flex flex-col'>
                <label for='email'
                       class='block text-sm font-medium text-gray-700'>
                    Email
                </label>
                <input type='email'
                       id='email'
                       name='email'
                       placeholder='Enter the email address you used to sign up with'
                       class='mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm'
                       v-model='emailAddress'/>
                <span id='email-helper-text'
                      v-if='emailAddress !== null'
                      class='text-xs text-black'><a href='/signIn?prefill=false'>Click Here if this is not you.</a></span>
            </div>

            <div class='flex flex-col'>
                <label for='Password'
                       class='block text-sm font-medium text-gray-700'>
                    Password
                </label>

                <input type='password'
                       id='Password'
                       name='password'
                       placeholder='Please enter your password'
                       class='mt-1 rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm my-4'
                       v-model='password'/>
            </div>

            <!--                        <div class='col-span-6'>-->
            <!--                            <label for='MarketingAccept' class='flex gap-4'>-->
            <!--                                <input type='checkbox'-->
            <!--                                       id='MarketingAccept'-->
            <!--                                       name='marketing_accept'-->
            <!--                                       class='h-5 w-5 rounded-md border-gray-200 bg-white shadow-sm'/>-->

            <!--                                <span class='text-sm text-gray-700'>-->
            <!--                I want to receive emails about events, product updates and-->
            <!--                company announcements.-->
            <!--              </span>-->
            <!--                            </label>-->
            <!--                        </div>-->

            <div class='flex flex-col'>
                <button class='inline-block shrink-0 rounded-md border border-black px-12 py-3 text-sm font-medium text-black mb-8'
                        @click='signIn'>
                    Login
                </button>
            </div>
        </div>
    </section>
</template>
